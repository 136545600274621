
:root{
  --primary: black;
  --secondary: white;
}
body{
  background: var(--primary);
}

.center {
  justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--primary);
    min-height: 100vh;
}

.container{
  text-align: center;
  color: var(--secondary);

  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.logo{
  width: 5rem;
}

.btn{
  background: white;
    padding: 1rem;
    min-width: 7rem;
    font-weight: bolder;
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;
    /* transform: scale(1, 1); */
    transition: transform 0.2s ease;
}

.btn:hover {
  transform: scale(1.1, 1.1);
}

.signup-form {
  min-width: 30rem;
}



.form{
  background:var(--secondary);
    width: 30%;
    margin: 1rem auto;
    padding: 2rem;
    border-radius: 0.2rem;
}

.form__input{
  display: block;
  width: 100%;
  margin: 1rem 0;
  height: 2rem;
  border: 1px solid white;
  /* background: black; */
  /* color: white; */
  border-radius: 0.3rem;
  padding: 0.5rem;
  font-size: 1rem;
}

.biolink {
  background-color: var(--secondary);
  padding: 1rem;
  color: var(--primary);
  min-width: 30rem;
  margin-top: 1rem;
  border-radius: 0.4rem;
}

.biolink .btn {
  margin-top: 1rem;
}

.biolink__footer a{
  color: white;
}

.biolink__button {
  display: block;
  padding: 1.1rem;
  background: white;
  border-radius: 0.2rem;
  color: black;
  font-size: 1.4rem;
  text-decoration: none;
  margin: 1rem 0;
}

.biolink__group-heading{
  display: block;
  font-weight: bolder;
}

.biolink__group {
  padding: 1rem;
  border: 1px white solid;
  border-radius: 0.3rem;
}

.biolink__group-sub-heading{
  padding: 1rem;
}

.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

  
  